<template>
  <div class="api-keys-cards">
    <div class="api-keys-cards__cards-container">
      <el-card v-for="(item, index) in apikeys" :key="index">
        <div class="card-section">
          <div class="card-section__status">
            <span>Status</span>
            <div class="card-section__switch">
              <el-switch v-model="item.is_active" @change="updateStatus($event, item.id)">
              </el-switch>
              <span>{{ item.is_active === true ? "Active" : "Inactive" }} </span>
            </div>
          </div>
          <div class="card-section__created">
            <span> Created at: </span>
            <span>
              {{ getCreatedDate(item.create_at) }}
            </span>
          </div>
        </div>
        <div class="card-section">
          <div class="input-documents">
            <input
              class="input-information"
              type="password"
              ref="input"
              v-model="item.value"
              :placeholder="item.value"
            />
            <button class="tooltip-icon" @click="copyPassword(index)">
              <i class="ion ion-copy-outline"></i>
            </button>
          </div>
          <button
            class="tooltip-icon"
            type="button"
            ref="button"
            @click="changeTypeOfInput(item, index)"
          >
            <i :class="item.eyeIcon"></i>
          </button>
        </div>
        <div class="card-section__email">
          <span> Email: </span>
          <span>
            {{ item.contact_email }}
          </span>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "KeyCards",
  props: {
    apikeys: Array,
  },

  methods: {
    changeTypeOfInput(apiKey, index) {
      const inputId = this.$refs.input[index];
      if (inputId.type === "password") {
        inputId.type = "text";
        apiKey.eyeIcon = "ion ion-eye-outline";
      } else {
        inputId.type = "password";
        apiKey.eyeIcon = "ion ion-eye-off-outline";
      }
    },
    copyPassword(index) {
      const inputValue = this.$refs.input[index].placeholder;
      navigator.clipboard.writeText(inputValue);
      this.$message({
        type: "success",
        message: "Copied",
        duration: 1000,
      });
    },
    async updateStatus(event, apiKeyId) {
      const payload = {
        id: apiKeyId,
        body: {
          is_active: event,
        },
      };
      await this.$store.dispatch("apiKey/updateStatusApiKey", payload);
    },
    getCreatedDate(date) {
      const isoDate = new Date(date).toISOString();
      return dayjs(isoDate).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.api-keys-cards {
  &__cards-container {
    margin: 20px 10px 40px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 700px) {
      justify-content: center;
    }
  }
}

.el-card {
  width: auto;
  border-radius: 10px;
}

.card-section {
  display: flex;
  justify-content: space-between;
  color: $color-primary-company;
  &__status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include font-small-standart-text;
  }
  &__switch {
    margin: 2px 0 20px 0;
    span {
      margin-left: 10px;
    }
  }
  &__created {
    @include font-small-information;
    display: flex;
    flex-direction: column;
  }
  &__email {
    @include font-small-information;
    text-align: left;
    // display: flex;
    // flex-direction: column;
  }
}

.input-documents {
  @include input-information;
  align-items: center;
  border: 1px solid $color-border-container;
  padding: 7px 0px 5px 5px;
  height: 30px;
  width: 85%;
  box-shadow: none;
  border-radius: 10px;
  padding: 7px 5px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
  i {
    font-size: 16px;
    color: $color-primary-company;
  }
}
.input-information {
  @include input-password;
  padding: 0px;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
}

.tooltip-icon {
  border: none;
  background-color: inherit;
  color: $color-primary-company;
}

.ion-eye-off-outline:before,
.ion-eye-outline:before {
  font-size: 20px;
}

::v-deep .el-card__body {
  padding: 30px;
  width: 310px;
}

::v-deep .el-switch.is-checked .el-switch__core {
  border-color: #00ff00;
  background-color: #00ff00;
}

::v-deep .el-switch__core {
  border-color: $color-gray-dark;
  background-color: $color-gray-dark;
}
</style>
