<template>
  <div class="keys-modal">
    <button class="keys-modal__button" @click="showModal = true">Generate key</button>
    <CustomModal
      v-model="showModal"
      size="md"
      :centered="true"
      :showOverlay="sendingRequest"
      :closeOnBackDrop="false"
      :closeOnEsc="false"
      modalTitle="Generate Key"
    >
      <template #modal-content>
        <div class="keys-modal__content">
          <p class="keys-modal__instruction">
            Please provide an email, which will be associated with the new key.
          </p>
          <div class="input-container">
            <RCInput
            :class="emptyFields['apiKeyEmail'] ? 'missing-field' : ''"
            class="keys-modal__input"
            v-model="formData.apiKeyEmail"
            :requiredField="true"
            label="Contact email"
          />
          </div>
          <div class="webhook">
            <div class="webhook__status">
              <b-form-checkbox class="document-item__checkbox" v-model="formData.webhookStatus" />
              <span> Webhook </span>
            </div>
            <div class="webhook__url">
              <RCInput
                :class="emptyFields['webhookUrl'] ? 'missing-field' : ''"
                class="keys-modal_url"
                v-model="formData.webhookUrl"
                :requiredField="formData.webhookStatus ? true : false"
                label="Webhook Url"
              />
            </div>
          </div>
          <div class="button-toolbar">
            <button class="button-toolbar__cancel" @click="closeModal()">
              {{ $t("userManagement.cancel") }}
            </button>
            <button class="button-toolbar__save" @click="generateNewApiKey()">
              {{ $t("userManagement.save") }}
            </button>
          </div>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import EmailValidation from "../../../../utils/EmailValidation";
import CustomModal from "../../../../components/CustomModal.vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";

export default {
  name: "KeyGeneratorModal",
  components: {
    CustomModal,
    RCInput,
  },
  data() {
    return {
      sendingRequest: false,
      showModal: false,
      isRequiredWebhook: false,
      formData: {
        apiKeyEmail: "",
        webhookStatus: false,
        webhookUrl: "",
      },
      emptyFields: {
        apiKeyEmail: false,
        webhookUrl: false,
      },
    };
  },
  methods: {
    async generateNewApiKey() {
      const { validation } = this.validateFields();
      if (!validation) {
        this.setEmptyFields();
        this.missingFieldsMessage();
        return;
      }
      const isValidEmail = EmailValidation(this.formData.apiKeyEmail);
      if (!isValidEmail) {
        this.missingFieldsMessage("Invalid email", "Please provide a valid email");
        return;
      }
      const validateURL = this.validateUrlString(this.formData.webhookUrl);
      if (!validateURL) {
        this.missingFieldsMessage("Invalid URL", "Please provide a valid url webhook");
        return;
      }
      this.sendingRequest = true;
      const newApiKey = await this.$store.dispatch("apiKey/createApiKey", {
        contact_email: this.formData.apiKeyEmail,
        webhook_is_active: this.formData.webhookStatus,
        webhook_url: this.formData.webhookUrl,
      });
      this.sendingRequest = false;
      this.showModal = false;
      this.$emit("generateNewApiKey", newApiKey);
      this.closeModal();
    },
    closeModal() {
      this.formData.apiKeyEmail = "";
      this.formData.webhookStatus = false;
      this.formData.webhookUrl = "";
      this.showModal = false;
    },
    validateWebHook() {
      if (this.formData.webhookStatus && this.formData.webhookUrl === "") {
        return true;
      }
      return false;
    },
    validateFields() {
      const objetData = { ...this.formData };
      if (!this.formData.webhookStatus) {
        delete objetData.webhookStatus;
        delete objetData.webhookUrl;
      }
      const validate = ValidateEmptyFields(objetData);
      this.resetEmptyFieldsValues();
      validate.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      return validate;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    setEmptyFields() {
      this.emptyFields = {
        apiKeyEmail: this.formData.apiKeyEmail.length < 1,
        webhookUrl:
          this.formData.webhookStatus &&
          this.formData.webhookUrl === "" &&
          !this.validateUrlString(this.formData.webhookUrl),
      };
    },
    missingFieldsMessage(title, message) {
      this.swal({
        title: title || "Missing fields",
        text: message || "Please provide the required information",
        icon: "warning",
      });
    },
    validateUrlString(url) {
      if (this.formData.webhookStatus) {
        return /^https:\/\//.test(url);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Modal.scss";
@import "../../../../assets/scss/components/RCComponents.scss";
@import "@/assets/scss/components/Checkboxes.scss";

.keys-modal {
  &__button {
    @include primary-button($heightSize: 40px);
    @include font-button-text;
    min-width: 150px;
    padding: 5px 3%;
    font-weight: 400;
    i {
      font-size: 16px;
    }
  }
  @include font-standart-text;
  &__header {
    display: flex;
  }
  &__modal-title {
    @include modals-title;
    color: $color-border-container;
  }
  &__close-button {
    text-align: right;
  }
  &__instruction {
    text-align: left;
  }
  &__input {
    &:focus {
      outline: none;
    }
    &--searcher {
      margin-bottom: 15px;
    }
  }
}

.button-toolbar {
  display: flex;
  &__save {
    @include primary-button;
    background-color: $color-save-button;
    padding: 7px;
    width: 47.5%;
    &:hover {
      background-color: $color-save-button;
    }
  }
  &__cancel {
    @include opposite-secondary-button;
    @include secondary-button();
    outline: 0;
    padding: 7px;
    width: 47.5%;
  }
}

.webhook {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  &__status {
    display: flex;
  }
}

::v-deep {
  .rc-input-field {
    width: 330px;
  }
}
</style>
