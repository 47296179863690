<template>
  <div class="api-keys">
    <div class="api-keys__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="api-keys__elements-container">
      <b-overlay :show="loading" class="overlay-div" rounded="sm">
        <div class="api-keys__buttons-toolbar">
          <KeyGeneratorModal @generateNewApiKey="generateNewApiKey" />
          <a class="documentation-link" href="https://docs.webservices.goglt.com/" target="_blank"
            >API Docs <i class="ion ion-open-outline"></i
          ></a>
        </div>
        <Cards :apikeys="apikeys" />
        <template #overlay>
          <div class="loading" v-show="loading">
            <b-icon icon="truck" font-scale="3" animation="cylon" class="loading__text"></b-icon>
            <p class="loading__text">{{ $t("loading") }}...</p>
          </div>
        </template>
      </b-overlay>
      <div class="api-keys__not-found" v-if="!loading && apikeys.length === 0">
        <i class="ion ion-file-tray-outline api-keys__not-found-icon"></i>
        <p>No Api Keys found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { generalMessage } from "../../../utils/ErrorMessages";
import Cards from "./components/Cards.vue";
import KeyGeneratorModal from "./components/ApiKeyGenerator.vue";

export default {
  name: "Keys",
  components: {
    Cards,
    KeyGeneratorModal,
  },
  data() {
    return {
      apikeys: [],
      loading: true,
    };
  },
  async created() {
    const allApiKeys = await this.$store.dispatch("apiKey/getApiKeysByCurrentClient");
    this.apikeys = allApiKeys.map((item) => ({
      ...item,
      eyeIcon: "ion ion-eye-off-outline",
    }));
    this.loading = false;
  },
  methods: {
    async generateNewApiKey(data) {
      if (data) {
        generalMessage("New key generated");
        data.eyeIcon = "ion ion-eye-off-outline";
        if (data.company_name) {
          data.account = {
            account_name: data.company_name.toUpperCase(),
          };
        }
        this.apikeys.unshift(data);
      }
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.documentation-link {
  @include font-button-text;
  color: $color-secondary-button-text;
  outline: 0;
  padding: 5px 1%;
  width: auto;
  font-weight: 400;
  // margin: 20px 0px 20px 10px;
  i {
    font-size: 16px;
  }
}

.api-keys {
  @include splitted-view-styles;
  padding-top: 4%;
  padding-bottom: 1px;
  &__buttons-toolbar {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__elements-container {
    margin: 0px 10px;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  &__not-found {
    color: $color-primary-company;
    p {
      margin-bottom: 0;
    }
  }
  &__not-found-icon {
    font-size: 50px;
  }
}

.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

::v-deep .bg-light {
  background-color: $color-white !important;
}
</style>
